.collective-annual-vacation-input {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 20px;
}

.collective-annual-vacation-buttons {
  display: flex;
}
