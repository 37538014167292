.working-hours-input-day {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  grid-template: 'dayName dayName';
  align-items: center;
}

.working-hours-input-day-name {
  display: flex;
  grid-area: dayName;
}

/* .working-hours-input-day-off{
      width: 100%;
  } */

.working-hours-input-day-name h5 {
  margin-bottom: 0;
}
